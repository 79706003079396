import React from 'react';
import styled from "styled-components"
import {P} from "./ui/P";
import aboutImg from "./../img/about.png"
import aboutImg2 from "./../img/abou2.png"
import caImg from "./../img/convichka.svg"
import check from "./../img/check.svg"
import {useTranslation} from "react-i18next";
import {Element} from "react-scroll";

const AboutStyled = styled.div`
  padding: 60px 0;

  > h1 {
    text-align: center;
  }

  > p {
    max-width: 851px;
    margin: 15px auto 0;
    text-align: center;
    padding: 0 35px;

    
  }

  > div {
    margin: 74px 65px 0 65px;

    > div {
      position: relative;

      > img {
        position: absolute;
        height: 400px;
      }

      > div {
        background: #0E5722;
        border-radius: 50px;
      }
      
      @media(max-width: 1240px) {
        display: grid;
        grid-gap: 30px;
        align-items: center;
        > img {
          width: 100%;
          height: auto;
          position: static;
        }
        
        &:first-child {
          grid-template-columns: 2fr 3fr;
          > div {
            padding: 36px !important;
            margin-left: 0 !important;
          }
        }
        
        &:last-child {
          grid-template-columns: 3fr 2fr;
          > div {
            padding: 43px 50px !important;
            height: auto !important;
            margin-right: 0 !important;
          }
        }
        
        @media(max-width: 889px) {
          display: block;
          
          > img {
            display: block;
            max-width: 400px;
            margin: 0 auto;
          }
          
          &:first-child {
            > div {
              margin-top: -30px;
            }
          }

          &:last-child {
            margin-top: 65px !important;
            > img {
              margin-top: -120px;
            }
            > div {
              position: relative;
              z-index: 1;
            }
            @media(max-width: 568px) {
              margin-top: 35px !important;
            }
          }
        }
      }

      &:first-child {
        > img {
          top: -42px;
          z-index: 1;
        }

        > div {
          padding: 36px 56px 36px 286px;
          margin-left: 171px;
          position: relative;

          > p {
            margin-top: 30px;
            text-indent: 40px;

            &:first-child {
              margin-top: 0;
            }
          }

          > img {
            position: absolute;
            right: 76px;
            bottom: 41px;
          }
        }
      }

      &:last-child {
        margin-top: 103px;

        > img {
          right: 0;
          top: -21px;
        }

        > div {
          padding: 43px 80px 52px 80px;
          margin-right: 194px;
          height: 359px;

          > h1 {
            color: #fff;
          }

          > ul {
            margin-top: 35px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 25px;

            > li {
              display: flex;
              align-items: center;
              column-gap: 20px;
              font-size: 18px;
              color: #f8f8f8;
              font-weight: 600;

              > img {
                height: 18px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 568px) {
      margin: 40px 35px 0 35px;
    }
  
  }
`

const AboutOurAdvantage = () => {
    const {t} = useTranslation("common")


    return (
        <AboutStyled>
            <h1>{t("nav.0")}</h1>
            <P>{t("about.0")}</P>
            <div>
                <div>
                    <img src={aboutImg} alt=""/>
                    <div>
                        <P white large normal>{t("about.1")}</P>
                        <P white>{t("about.2")}</P>
                        <P white>{t("about.3")}</P>
                        <img src={caImg} alt=""/>
                    </div>
                </div>
                <Element name="advantage">
                    <div>
                        <h1>{t("nav.1")}</h1>
                        <ul>
                            <li><img src={check} alt=""/>{t("ourAdvantage.0")}</li>
                            <li><img src={check} alt=""/>{t("ourAdvantage.1")}</li>
                            <li><img src={check} alt=""/>{t("ourAdvantage.2")}</li>
                        </ul>
                    </div>
                    <img src={aboutImg2} alt=""/>
                </Element>
            </div>
        </AboutStyled>
    );
};

export default AboutOurAdvantage;