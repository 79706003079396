import React from 'react';
import styled, {css} from "styled-components";


const PStyled = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  ${props => props.white && css`
    color: #F8F8F8;
  `}

  ${props => props.large && css`
    font-size: 18px;
    font-weight: 600;
  `}

  ${props => props.normal && css`
    font-style: normal;
  `}

  ${props => props.bold && css`
    font-weight: 600;
  `}

  ${props => props.blue && css`
    color: #252A4B;
  `}
`


export const P = ({children, ...rest}) => <PStyled {...rest}>{children}</PStyled>