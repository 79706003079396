import React from 'react';
import styled from "styled-components";
import {P} from "./ui/P";
import pro1 from "./../img/1.png"
import pro2 from "./../img/2.png"
import pro3 from "./../img/3.png"
import {useTranslation} from "react-i18next";

const StyledProducts = styled.div`
  padding: 40px 0;
  > h1 {
    text-align: center;
  }
  
  > div {
    padding: 0 65px;
    max-width: 880px;
    margin: 70px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    grid-gap: 65px;
    
    > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      > h1 {
        color: #DE1101;
        font-size: 56px;
        margin-top: 20px;
      }
    }
    
    > img {
      width: 100%;
    }

    @media(max-width: 835px) {
      grid-template-columns: 1fr;
      
      > img {
        display: block;
        max-width: 400px;
        margin: 0 auto;
      }

      @media(max-width: 568px) {
        padding: 0 35px;
        grid-gap: 35px;
        margin-top: 60px !important;
      }
    }
  }


  @media(max-width: 568px) {
    padding-top: 0 !important;
  }

  
 
`

const Products = () => {
    const {t} = useTranslation("common")

    return (
        <StyledProducts>
            <h1>{t("nav.2")}</h1>
            <div>
                <img src={pro1} alt=""/>
                <div>
                    <h1>01</h1>
                    <P large normal blue>{t("products.one.0")}</P>
                    <P>{t("products.one.1")}</P>
                </div>
                <div>
                    <h1>02</h1>
                    <P large normal blue>{t("products.two.0")}</P>
                    <P>{t("products.two.1")}</P>
                    <P>{t("products.two.2")}</P>
                </div>
                <img src={pro2} alt=""/>
                <img src={pro3} alt=""/>
                <div>
                    <h1>03</h1>
                    <P large normal blue>{t("products.there.0")}</P>
                    <P>{t("products.there.1")}</P>
                </div>
            </div>
        </StyledProducts>
    );
};

export default Products;