import React from 'react'
import imgLoaderApp from './../../img/header/logo.png'
import styled from "styled-components";

const LoaderAppStyled = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 99999;
  transition: 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;


  > img {
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(0.9);
    }
  }
`

function LoaderApp() {
    return <LoaderAppStyled>
        <img src={imgLoaderApp} alt=""/>
    </LoaderAppStyled>
}

export default LoaderApp
