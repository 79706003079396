import React from 'react';
import styled from "styled-components"
import bgFooter from "./../img/footer.png"
import mail from "./../img/mail.svg"
import {P} from "./ui/P";
import {FaFacebook, FaInstagram, FaTelegram} from "react-icons/fa";
import footer1 from "./../img/footer1.png"
import footer2 from "./../img/footer2.png"
import {useTranslation} from "react-i18next";

const FooterStyled = styled.div`
  > img {
    width: 100%;
  }

  > div {
    margin-top: -10px;
    background: #0E5722;
    position: relative;
    padding: 0 35px;

    > h1 {
      text-align: center;
      color: #fff;
      padding-top: 35px;
    }

    > p {
      text-align: center;
      margin-top: 15px;
    }

    > form {
      display: block;
      max-width: 569px;
      margin: 50px auto 0;
      position: relative;
      z-index: 2;
      > input {
        width: 100%;
        padding: 17px 140px 17px 40px;
        font-size: 18px;
        border-radius: 150px;
        border: none;
        outline-color: #0E5722;
      }

      > button {
        position: absolute;
        border-radius: 100px;
        background: #DE1101;
        top: 2px;
        right: 2px;
        width: 120px;
        @media(max-width: 568px) {
          width: 80px;
        }
        height: 52px;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }

    > div {
      margin-top: 109px;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      row-gap: 30px;
      flex-wrap: wrap;
      
      @media(max-width: 568px) {
        margin-top: 60px;
      }

      > span {
        display: flex;
        align-items: center;
        column-gap: 30px;
        row-gap: 30px;
        flex-wrap: wrap;

        > a {
          color: #f8f8f8;
          font-size: 16px;
        }
      }

      > div {
        display: flex;
        align-items: center;
        column-gap: 15px;

        > svg {
          font-size: 26px;
          color: #f8f8f8;

          > * {
            color: #f8f8f8;
          }
        }
      }
    }

    > span {
      display: block;
      text-align: center;
      font-size: 12px;
      color: #f8f8f8;
      padding: 27px 0;
      border-top: 1px solid rgba(198, 171, 138, 0.2);
    }
    
    > img {
      position: absolute;
      z-index: 1;
    }

    .onef1 {
      top: 80px;
      left: 90px;
      @media(max-width: 922px) {
        display: none;
      }
    }

    .onef2 {
      top: 153px;
      right: 245px;
    }
  }
  
  
`

const Footer = () => {
    const {t} = useTranslation("common")

    return (
        <FooterStyled>
            <img src={bgFooter} alt=""/>
            <div>
                <h1>{t("nav.3")}</h1>
                <P white>{t("contacts.0")}<br/> {t("contacts.1")}</P>
                <form action="">
                    <input type="text" placeholder={t("contacts.2")}/>
                    <button>
                        <img src={mail} alt=""/>
                    </button>
                </form>
                <div>
                    <span>
                        <a href="">{t("contacts.3")}</a>
                        <a href="">{t("contacts.4")}</a>
                        <a href="">{t("contacts.5")}</a>
                    </span>
                    <div>
                        <FaInstagram />
                        <FaFacebook />
                        <FaTelegram />
                    </div>
                </div>
                <span>
                    {t("contacts.6")}
                </span>
                <img className="onef1" src={footer1} alt=""/>
                <img className="onef2" src={footer2} alt=""/>
            </div>
        </FooterStyled>
    );
};

export default Footer;