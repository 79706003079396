import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Header from "./components/Header";
import AboutOurAdvantage from "./components/AboutOurAdvantage";
import Products from "./components/Products";
import Footer from "./components/Footer";
import {scroller} from "react-scroll";
import {Element} from 'react-scroll'
import LoaderApp from "./components/ui/LoaderApp";
import 'aos/dist/aos.css';
import AOS from 'aos';

const StyledApp = styled.div`
  margin: 0 auto;
  max-width: 1350px;
  box-shadow: 0 0 70px rgb(203 203 203);
  background: #fff;
  overflow-x: hidden;
`

const App = () => {
    const [loaderApp, setLoaderApp] = useState(true)


    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    useEffect(() => {
        AOS.init({
            duration : 800,
            once: true
        })
    }, [])

    useEffect(() => {
        setTimeout(() => setLoaderApp(false), 1500)
    }, [])


    return <>
        {loaderApp
            ? <LoaderApp />
            : <StyledApp>
                <Header scrollTo={scrollTo}/>
                <Element name="about">
                    <AboutOurAdvantage/>
                </Element>
                <Element name="products">
                    <Products/>
                </Element>
                <Element name="contacts">
                    <Footer/>
                </Element>
            </StyledApp>
        }
    </>
};

export default App;