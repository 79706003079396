import React, {useEffect, useState} from 'react';
import styled from "styled-components"
import logo from "./../img/header/logo.png"
import pasta from "./../img/header/pasta.png"
import one1 from "./../img/header/1.svg"
import one2 from "./../img/header/2.svg"
import one3 from "./../img/header/3.svg"
import {P} from "./ui/P";
import {useTranslation} from "react-i18next";
import "/node_modules/hamburgers/dist/hamburgers.css";

const HeaderStyled = styled.div`

  > nav {
    background: #0E5722;

    > div {
      padding: 15px 65px;
      background: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;

      @media (max-width: 568px) {
        padding: 12px 35px;
      }


      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      > div {
        display: flex;
        align-items: center;
        column-gap: 80px;

        > img {
          height: 90px;

          @media (max-width: 768px) {
            height: 70px;
          }
        }

        > div {
          display: flex;
          align-items: center;
          column-gap: 50px;
          @media (max-width: 994px) {
            display: none;
          }

          > a {
            font-size: 18px;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
              color: #DE1101;
              transition: all .3s ease;
            }
          }
        }
      }

      > span {
        display: flex;
        align-items: center;
        column-gap: 30px;

        > a {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }

        > div {
          display: none;
          position: relative;
          @media (max-width: 994px) {
            display: block;
          }

          > button {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;

            > span {
              pointer-events: none;

              > span {
                background: #0E5722;

                &:after {
                  background: #0E5722;
                }

                &:before {
                  background: #0E5722;
                }
              }
            }
          }

          > ul {
            position: absolute;
            background: #fff;
            padding: 20px;
            right: 0;
            margin-top: 10px;
            border-radius: 20px;
            min-width: 150px;
            z-index: 1;
            white-space: nowrap;
            box-shadow: 0 5px 15px rgba(204, 204, 204, 0.78);


            > li {
              cursor: pointer;
              line-height: 32px;
              color: #6D6F7A;
            }
          }
        }
      }


    }
  }

  > div {
    background: #0E5722;
    padding: 21px 100px 90px 100px;
    border-radius: 0 0 513px 213px / 0px 0px 354px 154px;

    > div {
      display: grid;
      grid-template-columns: 465px auto;
      align-items: center;
      position: relative;

      > div {
        > h1 {
          color: #fff;

          > i {
            color: #DE1101;
          }
        }

        > p {
          margin-top: 25px;
        }

        > button {
          margin-top: 40px;
          background: #DE1101;
          color: #fff;
          border-radius: 100px;
          padding: 15px 40px 15px 40px;
          cursor: pointer;
          outline: none;
          border: none;
          font-size: 16px;
          transition: all .1s ease;

          &:hover {
            background: rgba(236, 21, 2, 0.98);
          }

          &:active {
            transform: scale(0.95);
            transition: all .1s ease;
          }
        }
      }

      > img {
        position: relative;
        left: 23px;
        top: 17px;
        width: 100%;
      }
    }

    > span {
      display: flex;
      align-items: center;
      column-gap: 57px;

      > span {
        display: flex;
        align-items: center;
        column-gap: 10px;

        > i {
          font-size: 16px;
          font-style: normal;
          color: #f8f8f8;
        }
      }
    }


    @media (max-width: 1208px) {
      padding: 91px 100px 91px 100px;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 400px;

      > div {
        > img {
          left: 0;
          top: 0;
        }

      }

      > span {
        margin-top: 40px;
      }
    }

    @media (max-width: 1067px) {
      > div {
        display: block;
        position: relative;

        > img {
          position: absolute;
          left: 245px;
          top: 169px;
          max-width: 400px;
        }
      }

      > span {
        margin-top: 236px;
      }
    }

    @media (max-width: 861px) {
      > div {
        display: block;
        position: relative;
        text-align: center;

        > img {
          left: 355px;
          top: 301px;
          max-width: 400px;
        }
      }

      > span {
        row-gap: 30px;
        margin-top: 60px;
        flex-wrap: wrap;
      }
    }

    @media (max-width: 568px) {
      border-bottom-right-radius: 300px;
      padding: 60px;
      > div {
        > img {
          width: 200px;
          top: 187px;
        }
      }
    }

    @media (max-width: 468px) {
      border-bottom-right-radius: 200px;
      padding: 60px;
      > div {

        > div {
          > p {
            margin-top: 20px;
          }

          > button {
            margin-top: 30px;
          }
        }

        > img {
          width: 200px;
          top: 331px;
          left: 170px;
        }
      }

      > span {
        margin-top: 40px;
      }
    }
    @media (max-width: 440px) {
      padding: 60px 30px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      > div {
        > img {
          display: none;
          filter: blur(5px);
          width: 200px;
          top: 351px;
          left: 170px;
        }
      }
    }

  }

`

const Header = ({scrollTo}) => {
    const [isToggle, setIsToggle] = useState(false)
    const {t, i18n} = useTranslation("common")
    const [images, setImages] = useState({icon: pasta, transform: 1, x: 0, y: 0})

    useEffect(() => {
        document.addEventListener("mousemove", (e) => {
            setImages(prev => {
                const transform = prev.transform
                const x = (window.innerWidth - e.pageX * transform) / 100
                const y = (window.innerHeight - e.pageY * transform) / 100

                return {
                    ...prev,
                    x,
                    y
                }
            })
        })
    }, [])

    return (
        <HeaderStyled>
            <nav>
                <div>
                    <div>
                        <img src={logo} alt=""/>
                        <div>
                            <a onClick={() => scrollTo("about")}>{t("nav.0")}</a>
                            <a onClick={() => scrollTo("advantage")}>{t("nav.1")}</a>
                            <a onClick={() => scrollTo("products")}>{t("nav.2")}</a>
                            <a onClick={() => scrollTo("contacts")}>{t("nav.3")}</a>
                        </div>
                    </div>
                    <span>
                        <a onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}>{i18n.language === 'ru' ? 'EN' : 'RU'}</a>
                        <div>
                        <button
                            onClick={() => setIsToggle(prev => !prev)}
                            className={`menuBurgerLabel hamburger--3dy ${isToggle ? "is-active" : ''}`}
                            type="button"
                        >
                              <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                              </span>
                        </button>
                            {isToggle &&
                                <ul>
                                    <li onClick={() => scrollTo("about")}>{t("nav.0")}</li>
                                    <li onClick={() => scrollTo("advantage")}>{t("nav.1")}</li>
                                    <li onClick={() => scrollTo("products")}>{t("nav.2")}</li>
                                    <li onClick={() => scrollTo("contacts")}>{t("nav.3")}</li>
                                </ul>
                            }
                    </div>
                    </span>
                </div>
            </nav>
            <div>
                <div>
                    <div>
                        <h1 data-aos="zoom-in">{t("banner.main.0")} <i>{t("banner.main.1")}</i></h1>
                        <P data-aos="fade-up" white>{t("banner.main.2")}</P>
                        <button data-aos="zoom-in" onClick={() => scrollTo("contacts")}>{t("banner.main.3")}</button>
                    </div>
                    <img style={{transform: `translateX(${images.x}px) translateY(${images.y}px)`}} src={images.icon}
                         alt=""/>
                </div>
                <span>
                    <span data-aos="zoom-in-right">
                        <img src={one1} alt=""/>
                        <i>{t("banner.footer.0")} <br/>{t("banner.footer.1")}</i>
                    </span>
                    <span data-aos="zoom-in-right">
                        <img src={one2} alt=""/>
                        <i>{t("banner.footer.2")} <br/>{t("banner.footer.3")}</i>
                    </span>
                    <span data-aos="zoom-in-right">
                        <img src={one3} alt=""/>
                        <i>{t("banner.footer.4")}<br/> {t("banner.footer.5")}</i>
                    </span>
                </span>
            </div>
        </HeaderStyled>
    );
};

export default Header;